import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey there! I'm <span className="purple">Gaurav Arora</span>, a lead security engineer at Zepto, Bangalore's quick-commerce grocery delivery startup. By day, I'm all about:
            <br />
            <ImPointRight /> Security engineering
            <br />
            <ImPointRight /> Automation
            <br />
            <ImPointRight /> Web application firewalls
            <br />
            <ImPointRight /> Incident management
            <br />
            My journey into cybersecurity started with skipping college lectures to hack servers for fun. Now, I've turned that passion into a career.
            <br />
            When I'm not fortifying digital defenses, you'll find me:
            <br />
            <ImPointRight /> Jamming to Pink Floyd and Led Zeppelin
            <br />
            <ImPointRight /> Binging Tarantino and Scorsese flicks
            <br />
            <ImPointRight /> Laughing at Zakir Khan's stand-up
            <br />
            I keep it casual – in both my t-shirt and jeans style and my sarcastic, cool-yet-classy communication. Hit me up if you want to chat security, swap movie recommendations, or just share a laugh!
          </p>
          <footer className="blockquote-footer">Gaurav Arora</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
