import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Achievements from './Achievements';

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple">INTRODUCE</span> MYSELF
            </h1>
            <p className="home-about-body">
              🛡️ Security Engineer | 🐍 Python Enthusiast | 🎸 Rock Music Aficionado | 🎬 Classic Movie Buff
              <br />
              <br />
              I&apos;m a passionate Security Engineer with a knack for automation, scripting in Python, and a deep interest in cybersecurity. When I&apos;m not securing systems or diving into code, you might find me at a security conference, either absorbing wisdom or sharing some of my own.
              <br />
              <br />
              What I Do
              <br />
              🔒 Security Engineering & Automation: Making systems impenetrable and automating the heck out of everything security-related.
              <br />
              🚀 DevSecOps: Integrating security practices within DevOps to ensure the safe delivery of code in today&apos;s fast-paced world.
              <br />
              🐍 Python Scripting: Automating tasks, building tools, and creating bots that make life easier and more interesting.
              <br />
              🛠️ Incident Response: Swiftly navigating the stormy seas of security incidents to bring systems back to safety.
              <br />
              🧑‍🏫 Mentoring: Guiding cyber security enthusiasts through the maze of threats and defenses, sharing knowledge, and building the next generation of security experts.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12}  style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Achievements />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/emgaurav"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/0xGaurav"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/goa/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/taintain_toto/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
